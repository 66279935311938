window.addEventListener('DOMContentLoaded', () => {
  // リリースから2週間?までポップアップ表示
  // TODO: リリース日仮置き
  const releaseDate = new Date(("2022-05-11"));
  const popupEndAt = new Date(releaseDate.getTime());
  popupEndAt.setDate(releaseDate.getDate() + 14);
  const now = new Date();

  // 表示期限切れ
  if (popupEndAt.getTime() < now.getTime()) {
    return;
  }

  let last_open_popup = localStorage.getItem('last_open_popup')
  if (last_open_popup === null) {
    $('.popup').addClass('is_open');
    localStorage.setItem('last_open_popup', new Date().toString())
  } else {
    const last_open_popup_date = new Date(last_open_popup)
    last_open_popup_date.setHours(last_open_popup_date.getHours() + 24)
    if (last_open_popup_date.getTime() < now.getTime()) {
      $('.popup').addClass('is_open');
      localStorage.setItem('last_open_popup', new Date().toString())
    }
  }
  $('#popupCloseButton').click(() => {
    $('.popup').removeClass('is_open');
  })
});
